<template>
  <div class="page-view">
    <div class="page-view-container" :class="{ 'page-view-container-has-tab': isTabRouter }">
      <router-view></router-view>
    </div>
    <div class="page-view-tab" v-if="isTabRouter">
      <van-tabbar v-model="activeTab" @change="tabChange">
        <van-tabbar-item v-for="(item, index) in tab" :key="index" :name="item.path">
          <template #icon="props">
            <svg-icon :name="props.active ? item.activeIcon : item.icon"></svg-icon>
          </template>
          <span>{{ item.name }}</span>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import pageTabbarMixin from '../../mixins/pageTabbarMixin.js'

export default {
  name: '',
  mixins: [pageTabbarMixin],
  data() {
    return {
      activeTab: '/deviceView1/deviceRepairHomeIndex/deviceRepairHome',
      tab: [
        {
          path: '/deviceView1/deviceRepairHomeIndex/deviceRepairHome',
          name: '首页',
          icon: 'unActive-bottom-shouye',
          activeIcon: 'active-bottom-shouye'
        },
        {
          path: '/deviceView1/deviceRepairHomeIndex/deviceRepairMine',
          name: '我的',
          icon: 'unActive-bottom-I',
          activeIcon: 'active-bottom-I'
        }
      ]
    };
  },
  created() {

  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.page-view {
  width: 100%;
  height: 100%;
  .page-view-container {
    height: 100%;
    overflow: auto;

    &.page-view-container-has-tab {
      height: calc(100% - 50px);
    }
  }
}
</style>
